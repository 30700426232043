<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row >
      <v-col
        v-if="partner_mh"
        md="12"
        lg="6">
        <LatestOrders
          :orders="latestOrders"
          :loading="loading"
        />
      </v-col>
      <v-col
        v-if="partner_me"
        md="12"
        lg="6">
        <LatestConsultingAppointments
          :consultingAppointments="consultingAppointments"
          :loading="loading"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        md="12">
        <OrdersInProgress
          :orders="ordersInProgress"
          :loading="loading"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import DashboardAPI from '@/api/dashboard'
import LatestConsultingAppointments from '@/components/dashboard/LatestConsultingAppointments.vue'
import LatestOrders from '@/components/dashboard/LatestOrders.vue'
import OrdersInProgress from '../components/dashboard/OrdersInProgress.vue'

export default {
  data() {
    return {
      loading: false,
      consultingAppointments: null,
      latestOrders: null,
      ordersInProgress: null,
    }
  },
  created() {
    this.loadDashboard()
  },
  computed: {
    ...mapGetters({
      partner_me: 'auth/partner_me',
      partner_mh: 'auth/partner_mh',
    }),
  },
  methods: {
    loadDashboard() {
      this.loading = true
      DashboardAPI.getLatestInformation().then((response) => {
        this.loading = false
        this.consultingAppointments = response.consulting_appointments
        this.latestOrders = response.latestOrders
        this.ordersInProgress = response.ordersInProgress
      })
    },
    complete(index) {
      this.list[index] = !this.list[index]
    },
  },
  components: {
    OrdersInProgress,
    LatestOrders,
    LatestConsultingAppointments,
  },
}
</script>
